<ion-content class="invoice-complete">
  <ion-card class="container-card">
    <ion-grid>
      <ion-row class="billing-practice-container">
        <ion-col size="10" class="ion-text-start invoice-header-col">
          <ion-label color="primary" class="header-label">{{invoice.billingPractice.name}}</ion-label>
        </ion-col>
        <ion-col size="2">
          @if (invoice.invoiceType === invoiceTypes.Estimate && invoice?.invoiceStatus >= invoiceStatuses.Sent) {
            <ion-button fill="clear"
              [disabled]="downloadingFile" (click)="download(invoice)">
              <ion-icon color="tertiary" name="cloud-download-outline"></ion-icon>
            </ion-button>
          }
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="10" class="ion-text-start">
          <ion-label color="primary" class="sub-header-label">{{invoice.billingPractice.description}}</ion-label>
        </ion-col>
        <ion-col size="2" class="ion-text-end"></ion-col>
      </ion-row>
      <br />
      <!-- PRACTICE  -->
      <ion-row>
        <ion-col size="12" size-md="6">
          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.billingPractice?.postalAddress?.addressLine1}}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.billingPractice?.postalAddress?.addressLine2}}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.billingPractice?.postalAddress?.suburb}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.billingPractice?.postalAddress?.province}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.billingPractice?.postalAddress?.postalCode}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.billingPractice?.postalAddress?.country}}</ion-col>
          </ion-row>
        </ion-col>
        <ion-col size="12" size-md="6">
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Date</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.date | date}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Reference Number</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.invoiceNumber}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Practice Number</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.billingPractice?.number}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">VAT Number</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.billingPractice?.vatNo}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Work Phone</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.billingPractice?.workPhone}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Email</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.billingPractice?.email}}</ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <br />
      <!-- ACCOUNT HOLDER  -->
      <ion-row class="account-holder-container">
        <ion-col size="12" size-md="6">
          <ion-row>
            <ion-col class="ion-text-start header-label">{{invoice.accountInformation?.title}}
            {{invoice.accountInformation?.initials}} {{invoice.accountInformation?.lastName}}</ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.accountInformation?.postalAddress?.addressLine1}}
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.accountInformation?.postalAddress?.addressLine2}}
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.accountInformation?.postalAddress?.suburb}}</ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.accountInformation?.postalAddress?.city}}</ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="ion-text-start basic-text">{{invoice.accountInformation?.postalAddress?.postalCode}}
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col size="12" size-md="6">
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Cell Phone</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.accountInformation.cellPhone}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Email</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.accountInformation.email}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Medical Aid</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.accountInformation.medicalAid?.name}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Medical Aid #</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.accountInformation.medicalAidNumber}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Patient</ion-col>
            <ion-col size="6" class="ion-text-end"></ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start basic-text">{{invoice.patient.title}} {{invoice.patient.initials}}
            {{invoice.patient.lastName}}</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.patient.idNumber}}</ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <br />
      <!-- PROVIDERS  -->
      <ion-row>
        <ion-col size="12" size-md="6">
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Referring Provider</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.referringPractitioner.title}}
            {{invoice.referringPractitioner.initials}} {{invoice.referringPractitioner.lastName}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Treating Provider Number</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.treatingPractitioner.title}}
            {{invoice.treatingPractitioner.initials}} {{invoice.treatingPractitioner.lastName}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start"></ion-col>
            <ion-col size="6" class="ion-text-end"></ion-col>
          </ion-row>
        </ion-col>
        <ion-col size="12" size-md="6">
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Hospital</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.hospitalName}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Service Date</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">{{invoice.appointmentDate | date }}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-text-start label-text">Discount Applied</ion-col>
            <ion-col size="6" class="ion-text-end basic-text">R {{invoice.discountTotal | number : '1.2-2'}}</ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <br />
    </ion-grid>
    <ion-grid class="code-table">
      <ion-row class="table-header-row">
        <ion-col size="2" size-md="1">
          <ion-label color="primary">Code</ion-label>
        </ion-col>
        <ion-col size="7" size-md="4">
          <ion-label color="primary">Description</ion-label>
        </ion-col>
        <ion-col size="1" size-md="1" class="ion-hide-md-down">
          <ion-label color="primary">ICD 10</ion-label>
        </ion-col>
        <ion-col class="ion-text-end ion-hide-md-down" size="2" size-md="2">
          <ion-label class="ion-text-end" color="primary">Medical Aid</ion-label>
        </ion-col>
        <ion-col class="ion-text-end ion-hide-lg-down" size="1" size-md="2">
          <ion-label class="ion-text-end" color="primary">Patient</ion-label>
        </ion-col>
        <ion-col class="ion-text-end" size="3" size-md="2">
          <ion-label class="ion-text-end" color="primary">Total</ion-label>
        </ion-col>
      </ion-row>
      @for (item of invoice?.procedureCodes; track item) {
        <ion-row>
          <ion-col size="2" size-md="1">{{item?.code}}</ion-col>
          <ion-col size="7" size-md="4" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
          {{item.description}}</ion-col>
          <ion-col size="1" size-md="1" class="ion-hide-md-down">{{icd10CodeString}}</ion-col>
          <ion-col size="2" size-md="2" class="ion-text-end ion-hide-md-down"> {{item?.medicalAidAmount | number :
          '1.2-2'}} </ion-col>
          <ion-col size="1" size-md="2" class="ion-text-end ion-hide-lg-down"> {{item?.tariffTotal -item?.medicalAidAmount
          | number : '1.2-2'}} </ion-col>
          <ion-col size="3" size-md="2" class="ion-text-end"> {{item?.tariffTotal | number : '1.2-2'}} </ion-col>
        </ion-row>
      }

      @for (item of invoice?.nappiCodes; track item) {
        <ion-row>
          <ion-col size="2" size-md="1">{{item?.code}}</ion-col>
          <ion-col size="7" size-md="4" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
          {{item.description}}</ion-col>
          <ion-col size="1" size-md="1" class="ion-hide-md-down">{{icd10CodeString}}</ion-col>
          <ion-col size="2" size-md="2" class="ion-text-end ion-hide-md-down"> {{item?.medicalAidAmount | number :
          '1.2-2'}} </ion-col>
          <ion-col size="1" size-md="2" class="ion-text-end ion-hide-lg-down"> {{item?.tariffTotal -item?.medicalAidAmount
          | number : '1.2-2'}} </ion-col>
          <ion-col size="3" size-md="2" class="ion-text-end"> {{item?.tariffTotal | number : '1.2-2'}} </ion-col>
        </ion-row>
      }

      <ion-row class="totals-row ion-hide-md-down">
        <ion-col size="3" size-md="6" class="ion-text-start">
          @if (invoice.invoiceType === invoiceTypes.Estimate) {
            <strong>Estimated Total</strong>
          }
          @if (invoice.invoiceType === invoiceTypes.Invoice) {
            <strong>Total</strong>
          }
        </ion-col>
        <ion-col size="3" size-md="2" class="ion-text-end"><strong>R{{invoiceTotal - patientTotal | number :
        '1.2-2'}}</strong></ion-col>
        <ion-col size="3" size-md="2" class="ion-text-end"><strong>R{{patientTotal | number : '1.2-2'}}</strong>
      </ion-col>
      <ion-col size="3" size-md="2" class="ion-text-end"><strong>R{{invoiceTotal | number : '1.2-2'}}</strong>
    </ion-col>
  </ion-row>

  <ion-row class="totals-row ion-hide-sm-up">
    <ion-col size="6" class="ion-text-start">
      @if (invoice.invoiceType === invoiceTypes.Estimate) {
        <strong>Estimated Total</strong>
      }
      @if (invoice.invoiceType === invoiceTypes.Invoice) {
        <strong>Total</strong>
      }
    </ion-col>
    <ion-col size="6" class="ion-text-end"><strong>R{{invoiceTotal | number : '1.2-2'}}</strong></ion-col>
  </ion-row>

  @if (invoice.accountInformation.medicalAid) {
    <ion-row class="totals-row ion-hide-sm-up">
      <ion-col size="6" class="ion-text-start"><strong>{{invoice.accountInformation.medicalAid?.name}}</strong>
    </ion-col>
    <ion-col size="6" class="ion-text-end"><strong>R{{invoiceTotal - patientTotal | number : '1.2-2'}}</strong>
  </ion-col>
</ion-row>
}

<ion-row class="totals-row ion-hide-sm-up">
  <ion-col size="6" class="ion-text-start"><strong>{{invoice.patient.title}} {{invoice.patient.initials}}
  {{invoice.patient.lastName}}</strong></ion-col>
  <ion-col size="6" class="ion-text-end"><strong>R{{patientTotal | number : '1.2-2'}}</strong></ion-col>
</ion-row>
</ion-grid>
<br />
<!-- BANKING -->

<ion-row>
  <ion-col size="12" size-md="6">
    <ion-row>
      <ion-col size="12" class="ion-text-start">
        <strong>Banking Details</strong>
      </ion-col>
      <!-- <ion-col size="6" class="ion-text-start"></ion-col> -->
    </ion-row>
    <ion-row>
      <ion-col size="6" class="ion-text-start label-text">Bank</ion-col>
      <ion-col size="6" class="ion-text-start basic-text">{{invoice.billingPractice.bank}}</ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6" class="ion-text-start label-text">Branch Name</ion-col>
      <ion-col size="6" class="ion-text-start basic-text">{{invoice.billingPractice.branchName}}</ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6" class="ion-text-start label-text">Branch Code</ion-col>
      <ion-col size="6" class="ion-text-start basic-text">{{invoice.billingPractice.branchCode}}</ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6" class="ion-text-start label-text">Account Number</ion-col>
      <ion-col size="6" class="ion-text-start basic-text">{{invoice.billingPractice.bankAccountNumber}}</ion-col>
    </ion-row>
  </ion-col>
  <ion-col size="6"> </ion-col>
</ion-row>

<br />
<!-- TsCs -->

@if (invoice.invoiceType === invoiceTypes.Estimate) {
  <ion-row>
    <ion-col size="12" class="ion-text-start">
      <strong>Terms & Conditions</strong>
    </ion-col>
  </ion-row>
}

@if (invoice.invoiceType === invoiceTypes.Estimate) {
  <ion-row>
    <ion-col size="12" class="ion-text-start">
      <div [innerHTML]="invoice.billingPractice.quotationFooter"></div>
    </ion-col>
  </ion-row>
}

<br />

@if (invoice.invoiceType === invoiceTypes.Estimate) {
  <ion-row>
    <ion-col class="ion-text-end">
      @if (invoice.invoiceStatus !== 2048) {
        <ion-button (click)="acceptEstimate(invoice.id)" fill="outline">
        Accept</ion-button>
      }
      @if (invoice.invoiceStatus === 2048) {
        <ion-button fill="clear">Accepted<ion-icon color="success"
        name="checkmark-circle-outline"></ion-icon> </ion-button>
      }
    </ion-col>
  </ion-row>
}
</ion-card>
</ion-content>