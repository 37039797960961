import { Injectable } from '@angular/core';
import { IPatientConsent, IPatientHealthRecord, IPatientPhysicalRecord } from '../contracts/dto';
import { DataService } from './data-service';

@Injectable({ providedIn: 'root' })
export class HealthService {
  public consentRequired: IPatientConsent[] = [];
  public quoteReady = false;
  public quoteConsent = false;
  public privacySharingReady = false;
  public consentPersonal = false;
  public consentHealth = false;
  public healthStatusConfirmed = false;
  public medicalHistoryConfirmed = false;
  public healthInfoReady = false;

  constructor(private dataService: DataService) {}

  searchIngredients(q: string) {
    return this.dataService.searchIngredients(q);
  }

  getPatientHealthRecord(patientId: string) {
    return this.dataService.getPatientHealthRecord(patientId);
  }

  createPatientHealthRecord(patientId: string, rec: IPatientHealthRecord) {
    return this.dataService.postPatientHealthRecord(patientId, rec);
  }

  getPatientPhysicalRecord(patientId: string) {
    return this.dataService.getPatientPhysicalRecord(patientId);
  }

  createPatientPhysicalRecord(patientId: string, rec: IPatientPhysicalRecord) {
    return this.dataService.postPatientPhysicalRecord(patientId, rec);
  }

  searchMedications(searchString: string) {
    return this.dataService.searchMedications(searchString);
  }
}
