<div class="medication-manager-content">
  <ion-grid>
    @for (medication of data?.value; track medication) {
    <ion-row class="medication-row">
      <ion-col>
        <ion-row>
          <ion-col size="10">
            <ion-item lines="none">
              <h3>{{ medication.medicationName }}</h3>
            </ion-item>
          </ion-col>
          <ion-col size="2" class="ion-text-end">
            <ion-button fill="clear" (click)="removeMedication(medication)">
              <ion-icon slot="icon-only" name="trash-outline" color="primary"> </ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4">
            <ion-item>
              <ion-input type="number" [(ngModel)]="medication.dose" label="Dose" labelPlacement="floating">
              </ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="4">
            <ion-item>
              <ion-input type="text" [(ngModel)]="medication.unit" label="Unit" labelPlacement="floating">
              </ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="4">
            <ion-item>
              <ion-select [value]="'daily'" [(ngModel)]="medication.frequency" interface="action-sheet" label="Freq"
                label-placement="floating">
                <ion-select-option value="daily">daily</ion-select-option>
                <ion-select-option value="2pd">2 x per day</ion-select-option>
                <ion-select-option value="3pd">3 x per day</ion-select-option>
                <ion-select-option value="4pd">4 x per day</ion-select-option>
                <ion-select-option value="6pd">6 x per day</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    }
    @if (!data?.value || data.value.length === 0) {
    <ion-row>
      <ion-col>
        <ion-row class="no-data-row">
          <ion-col class="light-bold-text">NO MEDICATIONS CAPTURED</ion-col>
        </ion-row>
        <ion-row class="no-data-row">
          <ion-col class="light-bold-text"><ion-icon name="information-circle-outline" color="tertiary"></ion-icon>Click
            on the ADD MEDICATION button to capture medications</ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    }
    <br />
    <ion-row>
      <ion-col>
        <ion-button (click)="openMedicationSearch()" fill="clear" color="tertiary">ADD MEDICATION</ion-button>
      </ion-col>
    </ion-row>
    <br />
  </ion-grid>
</div>