export enum InvoiceStatus {
  New = 1, // New invoice or estimate
  Processing = 2, // Posting invoice to IHealth
  Successful = 4, // Success response from posting invoice to iHealth
  Failed = 8, // Error response from posting invoice to iHealth
  Estimated = 16, // New estimate created, could be replaced by NEW status?
  InReview = 32, // Invoice awaiting review from back office OR estimate awaiting review from practitioner
  Amended = 64, // Invoice amended/reactivated by back office OR estimate amended by practitioner
  Deleted = 128, // Invoice deleted from back office
  Approved = 256, // Estimate review approved by practitioner
  Sent = 512, // Estimate sent to account holder
  Read = 1024, // Estimate acknowledged by recipient
  Accepted = 2048 // Estimate accepted by reader/patient
}
