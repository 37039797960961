<!-- BEFORE ACTIVATION -->
@if (!activationSuccess) {
<div class="activation">
  <div>
    <ion-card color="primary" class="activation-content ion-padding">
      <form [formGroup]="authForm">
        <div class="logo-container">
          <img src="assets/img/logo.png" alt="nodeMe Logo" />
        </div>
        @if (userPatient) {
        <ion-label color="primary"> Hi, {{ userPatient.title }} {{ userPatient.firstName }} {{
          userPatient.lastName }} </ion-label>
        }
        <br />
        <a href="">Not you?</a>
        <p></p>
        <ion-item>
          <ion-input required type="email" autocomplete="username" formControlName="username" placeholder="Email"
            label="Email" labelPlacement="floating" color="primary"></ion-input>
        </ion-item>
        @if (username.invalid && (username.dirty || username.touched)) {
        <div class="validation-message-container">
          @if (username.errors['email'] || username.errors['required']) {
          <small> Valid Email address is required </small>
          }
        </div>
        }
        <ion-item>
          <ion-input required type="password" autocomplete="new-password" formControlName="password"
            placeholder="Type password" label="Password" labelPlacement="floating"></ion-input>
        </ion-item>
        @if (password.invalid && (password.dirty || password.touched)) {
        <div class="validation-message-container">
          @if (password.errors['minlength'] || password.errors['required']) {
          <small> Password must be at least 6 characters long </small>
          }
        </div>
        }
        <ion-item>
          <ion-input required type="password" autocomplete="new-password" formControlName="confirmPassword"
            placeholder="Retype" label="Confirm password" labelPlacement="floating"></ion-input>
        </ion-item>
        <div class="validation-message-container">
          @if (password.errors === null && confirmPassword.errors === null && password.value !== confirmPassword.value)
          {
          <small> Passwords do not match </small>
          }
        </div>
        <ion-item lines="none">
          <ion-checkbox slot="start" formControlName="tcs" color="secondary" required>
            <div class="ion-text-wrap">I have read and accept the <a href="https://mxnodeapp.co.za/privacy"
                target="_blank">terms and
                conditions</a></div>
          </ion-checkbox>
        </ion-item>
        <p>{{ error }}</p>
        <ion-button expand="full" color="primary"
          [disabled]="!activationCode.valid || !userPatient || !authForm.valid || password.value !== confirmPassword.value || !tcs.value"
          (click)="activateAccount()">
          ACTIVATE</ion-button>
      </form>
    </ion-card>
  </div>
</div>
}

@if (activationSuccess) {
<!-- AFTER ACTIVATION SMALL-->
<div class="activation-success-sm ion-hide-md-up">
  <ion-grid class="ion-no-padding">
    <ion-row class="header-row">
      <ion-col class="ion-no-padding">
        <img src="assets/img/handshake.jpeg" width="100%" alt="" />
      </ion-col>
    </ion-row>
    <ion-row class="content-row">
      <ion-col>
        <div class="header-text">
          <p class="color-grey">Help your doctor</p>
          <p class="color-grey">to</p>
          <p class="color-primary">help you</p>
        </div>
        <p>Your doctor needs your personal and health information to provide you with the highest standard of care</p>
        <ion-button color="primary" (click)="login()"> START</ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="footer-row">
      <ion-col class="ion-no-padding">
        <img src="assets/img/logo.png" width="70%" alt="nodeMe Logo" />
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<!-- AFTER ACTIVATION LARGE -->
<div class="activation-success-md ion-hide-md-down">
  <ion-grid class="ion-no-padding">
    <ion-row class="header-row">
      <ion-col class="ion-no-padding">
        <img src="assets/img/handshake.jpeg" height="100%" alt="" />
      </ion-col>
    </ion-row>
    <ion-row class="content-row">
      <ion-col>
        <div class="header-text">
          <p class="color-grey">Help your doctor</p>
          <p class="color-grey">to</p>
          <p class="color-primary">help you</p>
        </div>
        <p>Your doctor needs your personal and health information to provide you with the highest standard of care</p>
        <ion-button color="primary" (click)="login()"> START</ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="footer-row">
      <ion-col class="ion-no-padding">
        <img src="assets/img/logo.png" width="30%" alt="nodeMe Logo" />
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
}