import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { IPatientInvoice } from '@app/core/contracts/dto';
import { InvoiceStatus } from '@app/core/enums/invoice-status.enum';
import { InvoiceType } from '@app/core/enums/invoice-type.enum';
import { AccountService } from '@app/core/services/account-service';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';

@Component({
  selector: 'invoice-complete',
  templateUrl: './invoice-complete.html',
  styleUrls: ['./invoice-complete.scss']
})
export class InvoicesCompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() invoice: IPatientInvoice;
  componentDestroyed$: Subject<boolean> = new Subject();

  containsMetaData = false;
  icd10CodeString = '';
  invoiceTotal = 0;
  patientTotal = 0;
  // discountTotal = 0;
  discountPercentage = 0;
  saving = false;
  invoiceTypes = InvoiceType;
  invoiceStatuses = InvoiceStatus;
  downloadingFile = false;

  constructor(private accountService: AccountService) {}

  ngOnInit(): any {
    if (this.invoice) {
      this.loadData();
    }
  }

  ngOnChanges() {
    if (this.invoice) {
      this.loadData();
    }
  }

  loadData() {
    if (this.invoice.icd10Codes) {
      this.icd10CodeString = '';
      for (let c = 0; c < this.invoice.icd10Codes.length; c++) {
        if (this.icd10CodeString.length > 0) {
          this.icd10CodeString = this.icd10CodeString + ', ';
        }
        this.icd10CodeString = this.icd10CodeString + this.invoice.icd10Codes[c].code;
      }
    }

    const discountTotal = 0;
    this.invoice.procedureCodes.forEach((c) => {
      this.discountPercentage = c.discount;
    });

    this.invoiceTotal = this.invoice.total;
    this.patientTotal = this.invoice.patientAmount;
  }

  acceptEstimate(estimateId: string) {
    this.accountService.acceptEstimate(estimateId).subscribe(() => {
      this.invoice.invoiceStatus = InvoiceStatus.Accepted;
    });
  }

  download(invoice: IPatientInvoice) {
    this.downloadingFile = true;
    this.accountService.downloadInvoice(invoice.id, invoice.invoiceType).subscribe({
      next: (res) => {
        this.downloadFile(res, 'application/pdf', `${invoice.patient.firstName}${invoice.patient.lastName}_${invoice.invoiceNumber}`);
      },
      error: () => {
        this.downloadingFile = false;
      }
    });
  }

  downloadFile(data: any, type: string, name: string) {
    const blob = new Blob([data], { type: type });
    const pdfName = saveAs(blob, name);
    this.downloadingFile = false;
  }

  trackByFn(index, item) {
    if (!item) {
      return null;
    }
    return item.id;
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
