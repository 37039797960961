import { Component, Input } from '@angular/core';
import { IResource } from '@app/core/contracts/dto';
import { ContentType } from '@app/core/enums/content-type.enum';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-resource-viewer',
  templateUrl: './resource-viewer.component.html',
  styleUrls: ['./resource-viewer.component.scss']
})
export class ResourceViewerComponent {
  @Input() resource: IResource;
  contentType = ContentType;

  constructor(private modalController: ModalController) {}

  back() {
    this.modalController.dismiss();
  }
}
