import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from './auth-service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.check(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.check(route, state);
  }

  check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.authenticated().pipe(
      map((val) => {
        if (!val) {
          this.authService.logout();
          this.router.navigate([''], { queryParams: { returnUrl: state.url } });
        }
        return val;
      })
    );
  }
}
