<ion-app class="layout-app">
  @if (isLoading$ | async) {
  <ion-progress-bar style="position: absolute; z-index: 100" color="tertiary" type="indeterminate"></ion-progress-bar>
  }
  <ion-menu contentId="main-content" class="main-navbar">
    <div class="header-image-container">
      <img class="header-image" src="assets/img/logo.png" alt="nodeMe Logo" />
    </div>
    <ion-content>
      <ion-list class="menu-list">
        @if (authService.userContext) {
        <ion-list-header class="menu-header">
          <ion-label class="user-label">
            {{ authService.userContext.title | uppercase }} {{ authService.userContext.firstName | uppercase}} {{
            authService.userContext.lastName | uppercase}}
          </ion-label>
        </ion-list-header>
        }
        @for (p of appPages; track p; let i = $index) {
        <ion-menu-toggle auto-hide="false">
          <ion-item (click)="selectedIndex= i" routerDirection="root" [routerLink]="[p.url]" lines="none"
            [detail]="false" [class.selected]="selectedIndex === i">
            <ion-icon slot="start" [name]="p.icon"></ion-icon>
            <ion-label> {{ p.title }} </ion-label>
          </ion-item>
        </ion-menu-toggle>
        }
      </ion-list>
      <small class="version">v{{ appVersion }}</small>
    </ion-content>
  </ion-menu>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>
        <ion-grid>
          <ion-row>
            {{titleData}}
          </ion-row>
        </ion-grid>
      </ion-title>
      <ion-buttons slot="primary">
        <ion-button (click)="logout()">
          <ion-icon name="log-out-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="content-body">
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </div>
</ion-app>