import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ActivateComponent } from './components/activate/activate.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password';
import { AuthGuard } from './core/services/auth-guard';
import { AppLayoutComponent } from './layout/app';
import { PublicLayoutComponent } from './layout/public';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    data: { title: '' },
    children: [
      { path: '', pathMatch: 'full', component: LoginComponent },
      { path: 'activate/:code/:src', component: ActivateComponent },
      { path: 'activate', component: ActivateComponent },
      { path: 'forgotpassword', component: ForgotPasswordComponent },
      { path: 'resetpassword', component: ResetPasswordComponent }
    ]
  },
  {
    path: 'secure',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { title: 'Dashboard' }
      },
      {
        path: 'account',
        loadChildren: () => import('./features/account-information/account-information.module').then((m) => m.AccountInformationModule),
        data: { title: 'Personal Information' }
      },
      {
        path: 'health',
        loadChildren: () => import('./features/patient-health/patient-health.module').then((m) => m.PatientHealthModule),
        data: { title: 'My Health' }
      },
      {
        path: 'procedures',
        loadChildren: () => import('./features/procedures/procedures.module').then((m) => m.ProceduresModule),
        data: { title: 'Appointments' }
      },
      {
        path: 'estimates',
        loadChildren: () => import('./features/estimates/estimates.module').then((m) => m.EstimatesModule),
        data: { title: 'Estimates' }
      },
      {
        path: 'invoices',
        loadChildren: () => import('./features/invoices/invoices.module').then((m) => m.InvoicesModule),
        data: { title: 'Accounts' }
      },
      {
        path: 'legal',
        loadChildren: () => import('./features/legal/legal.module').then((m) => m.LegalModule),
        data: { title: 'Legal & Consent' }
      },
      {
        path: 'information',
        loadChildren: () => import('./features/information/information.module').then((m) => m.InformationModule),
        data: { title: 'Information' }
      }
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
