import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IActivatePatientUserIn,
  IBareMedicalAidPlan,
  IBareMedicalAidPlanGrouping,
  IBarePatient,
  IBareUserContext,
  IDynamicViewControl,
  IIdModel,
  IIngredient,
  ILogin,
  ILoginIn,
  ILookupCountry,
  IMedicationIngredient,
  IPasswordForgotIn,
  IPasswordResetIn,
  IPatientAppointment,
  IPatientFull,
  IPatientFullOut,
  IPatientHealthRecord,
  IPatientInvoice,
  IPatientPhysicalRecord,
  IPatientUserActivationResponse,
  IResource
} from '../contracts/dto';
import { Audience } from '../enums/audience.enum';
import { InvoiceType } from '../enums/invoice-type.enum';

@Injectable({ providedIn: 'root' })
export class DataService {
  constructor(private http: HttpClient) {}

  /***************************************
   Login & Token Refresh
  ***************************************/
  public loginUser(credentials: ILoginIn) {
    return this.http.post<ILogin>('accounts/login', JSON.stringify(credentials));
  }

  public refeshToken(token: string) {
    return this.http.get<ILogin>('accounts/token/refresh/' + token);
  }

  // public postRegister(registerUser: IRegisterUserIn) {
  //   return this.http.post<string>(
  //     'accounts/register/mobile',
  //     JSON.stringify(registerUser)
  //   );
  // }

  // public postRequestPasswordReset(body: IPasswordForgotIn) {
  //   return this.http.post<boolean>('accounts/forgot', JSON.stringify(body));
  // }

  public resetPassword(body: IPasswordResetIn) {
    return this.http.post<boolean>('accounts/reset', JSON.stringify(body));
  }

  public getUserContext() {
    return this.http.get<IBareUserContext>('users/context/bare?patientUser=true');
  }

  public getPatientById(id: string) {
    return this.http.get<IPatientFull>('patients/' + id);
  }

  public getPingResult() {
    return this.http.head<void>('accounts/ping'); // what does this do?
  }

  public getPatientByActivationCode(code: string) {
    return this.http.get<IBarePatient>('accounts/patient/' + code);
  }

  public getDashboardSummary() {
    return this.http.get<any>('users/dashboard');
  }

  public postRequestPasswordReset(body: IPasswordForgotIn) {
    return this.http.post<boolean>('accounts/forgot?client=5', JSON.stringify(body)); // client 5 = Patient App
  }

  public postPatientUserActivation(body: IActivatePatientUserIn) {
    return this.http.post<IPatientUserActivationResponse>('accounts/patient/activate', JSON.stringify(body));
  }

  public sendOTP(patientId: string, mobileNumber: string = null, verify: boolean = false) {
    return this.http.get<boolean[]>('patients/' + patientId + '/otp?mobileNumber=' + mobileNumber + '&verify=' + verify);
  }

  public updatePatientUser(patientId: string, patient: IPatientFullOut) {
    return this.http.put<boolean>('patients/' + patientId + '/user', JSON.stringify(patient));
  }

  public getCountries() {
    return this.http.get<ILookupCountry[]>('accountinformations/countries');
  }

  public searchMedicalAidGroupings(q: string) {
    return this.http.get<IBareMedicalAidPlanGrouping[]>('medicalaids/searchgroups?q=' + q);
  }
  public getMedicalAidPlansByScheme(id: string) {
    return this.http.get<IBareMedicalAidPlan[]>('medicalaids/' + id + '/plans/lookup');
  }
  public getPatientAccounts(patientId: string, invoiceType: InvoiceType) {
    return this.http.get<IPatientInvoice[]>('patients/' + patientId + '/invoices/' + invoiceType);
  }

  public getPatientAppointments(patientId: string) {
    return this.http.get<IPatientAppointment[]>('patients/' + patientId + '/appointments');
  }

  public acceptEstimate(estimateId: string) {
    return this.http.post<void>('billing/estimate/' + estimateId + '/accepted', {});
  }

  public downloadInvoice(id: string, invoiceType: InvoiceType): Observable<Blob> {
    if (invoiceType === InvoiceType.Invoice) {
      return this.http.get('billing/invoice/' + id + '/download?encrypt=false', {
        responseType: 'blob'
      });
    } else {
      return this.http.get('billing/estimate/' + id + '/download?encrypt=false', {
        responseType: 'blob'
      });
    }
  }
  // Health

  public searchMedications(q: string) {
    return this.http.get<IMedicationIngredient[]>('clinicalnotes/searchmedications?q=' + q);
  }

  public searchIngredients(q: string) {
    return this.http.get<IIngredient[]>('clinicalnotes/searchingredients?q=' + q);
  }

  // New implementation
  public getPatientHealthRecord(patientId: string) {
    return this.http.get<IPatientHealthRecord>('patients/' + patientId + '/health');
  }
  public postPatientHealthRecord(patientId: string, data: IPatientHealthRecord) {
    return this.http.post<IIdModel>('patients/' + patientId + '/health', JSON.stringify(data));
  }

  public getPatientPhysicalRecord(patientId: string) {
    return this.http.get<IPatientPhysicalRecord>('patients/' + patientId + '/physical');
  }
  public postPatientPhysicalRecord(patientId: string, data: IPatientPhysicalRecord) {
    return this.http.post<IIdModel>('patients/' + patientId + '/physical', JSON.stringify(data));
  }
  // New implementation

  // Dynamic Forms

  public getViewControls(audience: Audience = Audience.Patients) {
    return this.http.get<IDynamicViewControl[]>('views/audience?audience=' + audience);
  }

  // Resources
  public getPatientInformationResources(patientId) {
    return this.http.get<IResource[]>('patients/' + patientId + '/resources');
  }
}
