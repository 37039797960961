<ion-header>
  <ion-toolbar>
    <ion-searchbar [(ngModel)]="autocomplete.input" class="ion-no-padding" [debounce]="400"
      (ionChange)="updateSearchResults()" (ionInput)="updateSearchResults()" placeholder="Search for an address"
      autocomplete="off"></ion-searchbar>
  </ion-toolbar>
  <ion-list [hidden]="autocompleteItems.length === 0">
    @for (item of autocompleteItems; track item) {
    <ion-item tappable (click)="selectSearchResult(item)">
      {{ item.description }}
    </ion-item>
    }
  </ion-list>
  <div #postalCode></div>
</ion-header>