import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDynamicViewControl, IKeyValuePairAny, IPatientHealthRecord } from '@app/core/contracts/dto';
import { DynamicFieldType } from '@app/core/enums/dynamic-field-type.enum';
import { DynamicViewService } from '@app/core/services/dynamic-view-service';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToggleInstructionComponent } from '../toggle-instruction-modal/toggle-instruction.component';

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnChanges, OnDestroy {
  @Input() formKey = null;
  @Input() formData: IPatientHealthRecord = null;
  @Output() dataChanged: EventEmitter<IKeyValuePairAny[]> = new EventEmitter();
  @Output() customComponentDataChanged: EventEmitter<IKeyValuePairAny> = new EventEmitter();

  errorMessage = null;

  controls: IDynamicViewControl[] = [];
  formGroupControl: IDynamicViewControl;
  dynamicForm: FormGroup;
  componentDestroyed$: Subject<boolean> = new Subject();
  formBuilt = false;
  fieldTypes = DynamicFieldType;
  nonValueTypes: DynamicFieldType[] = [DynamicFieldType.DialogToggleInstruction, DynamicFieldType.Label];

  constructor(
    private dynamicViewService: DynamicViewService,
    private modalController: ModalController
  ) {}

  ngOnChanges() {
    if (!this.formKey) {
      this.errorMessage = 'Key not specified.';
      return;
    }
    this.loadFormControls();
  }

  buildForm(controls: IDynamicViewControl[]) {
    if (controls.length === 0) {
      this.errorMessage = 'No controls found';
    } else {
      this.errorMessage = null;
    }

    this.dynamicForm = new FormGroup({});
    this.formGroupControl = controls.find((c) => c.key === this.formKey);
    const containedControls = controls.filter((c) => c.key !== this.formKey && this.nonValueTypes.indexOf(c.fieldType) === -1); // remove container control, dialogs/non value types

    // save field values that default to true. Ex. Complications toggle - accepted as true unless unticked by user
    const defaultValues: IKeyValuePairAny[] = [];
    containedControls.forEach((c) => {
      const formControl = this.buildFormControl(c);
      this.dynamicForm.addControl(c.key, formControl);
      // if (formControl.value) {
      defaultValues.push({ key: c.key, value: formControl.value });
      // }
    });

    if (defaultValues.length > 0) {
      this.dataChanged.emit(defaultValues);
    }

    //

    this.setHiddenProperty();
    this.formBuilt = true;

    // Subscribe to form changes
    this.dynamicForm.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe({
      next: (changes) => {
        const newValues: IKeyValuePairAny[] = [];
        Object.entries(changes).forEach((element) => {
          newValues.push({ key: element[0], value: element[1] });
        });
        this.dataChanged.emit(newValues); //expect form control emission
      }
    });
  }

  customComponentUpdated(ev: IKeyValuePairAny) {
    // not form control
    ev.value = JSON.stringify(ev.value);
    this.customComponentDataChanged.emit(ev);
  }

  buildFormControl(control: IDynamicViewControl): FormControl {
    const formControl = new FormControl(this.getInitialValue(control), control.required ? [Validators.required] : []);
    return formControl;
  }

  setHiddenProperty() {
    this.controls.forEach((c) => {
      if (c.parentIsTrigger) {
        const parentField = this.controls.find((pc) => pc.id === c.parentId);
        const parentControl = this.dynamicForm.get(parentField.key);
        const hidden = parentControl.value === true ? false : true;
        c.hidden = hidden;

        if (hidden) {
          if (c.fieldType === DynamicFieldType.ComponentMedicalProblemManager) {
            const control = this.dynamicForm.get(c.key);
            control.setValue([]);
          } else {
            this.dynamicForm.get(c.key).setValue(this.getDefaultValue(c));
          }
        }
      }
    });
  }

  getInitialValue(control: IDynamicViewControl) {
    const initField = this.formData?.value?.find((c) => c.key === control.key);
    if (initField) {
      let initValue: any = initField.value;

      switch (control.fieldType) {
        case DynamicFieldType.CheckBox:
          initValue = this.convertToBoolean(initValue);
          break;
        case DynamicFieldType.ToggleYesNo:
          initValue = this.convertToBoolean(initValue);
          break;
        case DynamicFieldType.ComponentMedicationManager:
          initValue = JSON.parse(initValue);
          initField.value = initValue; // set value to object
          break;
        case DynamicFieldType.ComponentMedicalProblemManager:
          initValue = JSON.parse(initValue);
          initField.value = initValue; // set value to object
          break;
        case DynamicFieldType.ComponentPreviousSurgeries:
          initValue = JSON.parse(initValue);
          initField.value = initValue; // set value to object
          break;
      }

      return initValue;
    } else {
      return this.getDefaultValue(control);
    }
  }

  getDefaultValue(control: IDynamicViewControl): any {
    let defaultValue: any = control.defaultValue;

    if (control.fieldType === this.fieldTypes.CheckBox || control.fieldType === this.fieldTypes.ToggleYesNo) {
      defaultValue = this.convertToBoolean(JSON.parse(defaultValue));
    }

    if (defaultValue === null || defaultValue === undefined) {
      defaultValue = '';
    }
    return defaultValue;
  }

  getCustomControlData(key: string) {
    return this.formData.value.find((c) => c.key === key);
  }

  loadFormControls() {
    this.dynamicViewService
      .getControlsByKey(this.formKey)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (data) => {
          this.controls = data;
          this.buildForm(this.controls);
          this.displayFormDialogs(this.controls);
        }
      });
  }

  async displayFormDialogs(controls: IDynamicViewControl[]) {
    // display first dialog linked to form.
    // only one dialog type implemented currently, might change
    const diallogTypes: DynamicFieldType[] = [DynamicFieldType.DialogToggleInstruction];
    const dialogControl = controls.find((c) => diallogTypes.indexOf(c.fieldType) > -1);
    if (!dialogControl) {
      return;
    }

    let dialogRef = null;
    switch (dialogControl.fieldType) {
      case DynamicFieldType.DialogToggleInstruction:
        dialogRef = await this.modalController.create({
          component: ToggleInstructionComponent,
          backdropDismiss: false,
          componentProps: {
            message: dialogControl.generalDescription
          },
          cssClass: 'modal-small'
        });
        break;
    }

    await dialogRef.present();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  convertToBoolean(val: any) {
    if (val === null || val === undefined) {
      return null;
    } else if (val === true || (typeof val === 'string' && val.toLowerCase() === 'true') || val === 1) {
      return true;
    } else if (val === true || (typeof val === 'string' && val.toLowerCase() === 'false') || val === 0) {
      return false;
    } else {
      return null;
    }
  }
}
