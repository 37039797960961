import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { IMedicationIngredient } from '@app/core/contracts/dto';
import { HealthService } from '@app/core/services/health-service';
import { ModalController, SearchbarChangeEventDetail } from '@ionic/angular';
import { IonSearchbarCustomEvent } from '@ionic/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-medication-search',
  templateUrl: './medication-search.component.html',
  styleUrls: ['./medication-search.component.scss']
})
export class MedicationSearchComponent implements AfterViewInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  medications: IMedicationIngredient[] = [];
  @ViewChild('searchbar', { static: false }) medicationSearchbar: any;

  constructor(
    private healthService: HealthService,
    private modalController: ModalController
  ) {}

  ngAfterViewInit() {
    this.medicationSearchbar.setFocus();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  searchMedications(ev: IonSearchbarCustomEvent<SearchbarChangeEventDetail>) {
    if (!ev || !ev.target) {
      this.medications = [];
      return;
    }

    const q = ev.target.value;

    this.healthService
      .searchMedications(q)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (data) => {
          if (data) {
            this.medications = data;
          } else {
            this.medications = [];
          }
        }
      });
  }

  itemClicked(medication: IMedicationIngredient) {
    this.modalController.dismiss(medication);
  }

  cancel() {
    this.modalController.dismiss();
  }
}
