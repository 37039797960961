import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDynamicViewControl } from '../contracts/dto';
import { DynamicFieldType } from '../enums/dynamic-field-type.enum';
import { DataService } from './data-service';

@Injectable({ providedIn: 'root' })
export class DynamicViewService {
  private controls$: BehaviorSubject<IDynamicViewControl[]> = new BehaviorSubject([]);

  constructor(private dataService: DataService) {}

  flattenControlStructure(key: string, data: IDynamicViewControl[], output: IDynamicViewControl[]) {
    const keyValue = data.find((c) => c.key === key);
    if (!keyValue) {
      return;
    }

    output.push(keyValue); // Add parent

    const children = data.filter((c) => c.parentId === keyValue.id);
    children.forEach((c) => this.flattenControlStructure(c.key, data, output));
  }

  getControlsByKey(key: string) {
    if (!key) {
      this.controls$.next([]);
      return this.controls$.asObservable();
    } else {
      return this.getControls().pipe(
        map((data) => {
          const result: IDynamicViewControl[] = [];
          this.flattenControlStructure(key, data, result);
          return result;
        })
      );
    }
  }

  getForms() {
    return this.getControls().pipe(
      map((data) => {
        return data.filter((c) => c.fieldType === DynamicFieldType.FormGroup);
      })
    );
  }

  getSummaryControls() {
    const summaryControls: IDynamicViewControl[] = [];
    return this.getControls().pipe(
      map((data) => {
        data = data.filter((control) => control.displayOnSummary === true);
        const sections = data.filter((s) => s.fieldType === DynamicFieldType.FormGroup);
        if (sections.length === 0) {
          return [];
        }

        sections.forEach((s) => {
          this.flattenControlStructure(s.key, data, summaryControls);
          return summaryControls;
        });

        return summaryControls;
      })
    );
  }

  private getControls() {
    if (this.controls$.getValue().length > 0) {
      return this.controls$.asObservable();
    } else {
      const controlSubscription = this.dataService.getViewControls().subscribe({
        next: (controls) => {
          this.controls$.next(controls);
          return this.controls$.asObservable();
        },
        complete: () => {
          controlSubscription.unsubscribe();
        }
      });
      return this.controls$.asObservable();
    }
  }
}
