<div class="time-estimate-modal-content">
  <ion-grid>
    <ion-row>
      <ion-col>
        <h3>Time Estimates</h3>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <h4>
          This is an estimated time for your procedure.
          <b>The scheduled time can change without notice due to unforeseen circumstances</b>
        </h4>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <h4 class="color-primary">
          <b>Your doctor and theatre staff are working hard to help you as soon as possible</b>
        </h4>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <h5>
          As the hospital staff are managing many patients, there could be changes in the schedule due to unforeseen
          circumstances such as unbooked emergency cases, unexpected findings during surgery,
          or lack of theatre availability. The hospital staff are doing their best to accommodate all patients, and they
          will work hard to help you as soon as possible.
        </h5>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-button (click)="close()" color="primary"> I UNDERSTAND </ion-button>
</div>