import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { IonicStorageModule } from '@ionic/storage-angular';
import { featureComponents, featureServices } from './core.declarations';
import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { HttpAuthInterceptor } from './interceptors/http-auth-interceptor';
import { HttpValidParamsInterceptor } from './interceptors/http-valid-params.interceptor';
import { PendingRequestsInterceptor } from './interceptors/pending-requests-interceptor';
import { ServerErrorsInterceptor } from './interceptors/server-errors-interceptor';
import { ErrorHandlerService } from './services/errorhandler-service';

export const getAccessToken = () => {
  return localStorage.getItem('id_token');
};

@NgModule({
  declarations: [...featureComponents],
  exports: [CommonModule, JwtModule],
  imports: [
    CommonModule,
    IonicStorageModule.forRoot({
      driverOrder: ['localstorage', 'indexeddb', 'websql']
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: getAccessToken,
        skipWhenExpired: true
      }
    })
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiPrefixInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useExisting: PendingRequestsInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ServerErrorsInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpAuthInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpValidParamsInterceptor,
          multi: true
        },
        { provide: LOCALE_ID, useValue: 'en-ZA' },
        ...featureServices
      ]
    };
  }
}
