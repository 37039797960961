export const environment = {
  production: true,
  version: require('../../package.json').version,
  platform: 'production',
  appInsights: {
    instrumentationKey: 'd2fbd847-7871-4355-88be-ca89b6113e40',
    enableApplicationInsights: true
  },
  API_URL: 'https://api.mxnodeapp.co.za/v2/'
};
