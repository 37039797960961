import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/services/auth-service';
import { UserService } from '@app/core/services/user-service';
import { LoadingController, NavController, Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  error = '';
  authForm: FormGroup;
  returnUrl: string;
  username: FormControl<string>;
  password: FormControl<string>;

  constructor(
    private nav: NavController,
    private route: ActivatedRoute,
    private authService: AuthService,
    private loadingController: LoadingController,
    public platform: Platform,
    private activeRoute: ActivatedRoute,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.initFields();
    this.buildForm();
    this.addValueChanges();
    this.authService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/secure/dashboard';
    this.activeRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['returnUrl']) {
        this.returnUrl = queryParams['returnUrl'];
      }
    });
  }

  initFields() {
    this.username = new FormControl('', [Validators.required, Validators.minLength(4)]);
    this.password = new FormControl('', [Validators.required, Validators.minLength(4)]);
  }

  buildForm() {
    this.authForm = new FormGroup({
      username: this.username,
      password: this.password
    });
  }

  addValueChanges(): void {
    this.username.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.error = '';
    });
    this.password.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.error = '';
    });
  }

  async onSubmit() {
    if (this.authForm.valid) {
      const loading = await this.loadingController.create({
        message: 'Please wait...'
      });

      await loading.present();
      let loginModel = null;
      this.authService
        .login(this.authForm.value)
        .pipe(
          map((loginResponse) => {
            loginModel = loginResponse;
          }),
          concatMap(() => this.authService.setUserContext()),
          concatMap(() => this.userService.setUserPatient(this.authService.userContext.patientId))
        )
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: () => {
            loading.dismiss();
            if (loginModel.requiresPasswordChange) {
              this.authService.resetPasswordCode = this.authForm.value.password;
              this.nav.navigateRoot(['/resetpassword']);
            } else if (!this.userService.userPatient.verified) {
              this.nav.navigateRoot('/secure/account');
            } else {
              this.nav.navigateRoot(this.returnUrl);
            }
          },
          error: () => {
            loading.dismiss();
            this.error = 'Incorrect Username or Password provided';
          }
        });
    } else {
      this.error = 'Username and Password is required';
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
