import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IDynamicViewControl, IKeyValuePairAny } from '@app/core/contracts/dto';
import { ModalController, ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { MedicationSearchComponent } from './medication-search-modal/medication-search.component';

@Component({
  selector: 'app-medication-manager',
  templateUrl: './medication-manager.component.html',
  styleUrls: ['./medication-manager.component.scss']
})
export class MedicationManagerComponent implements OnInit, OnDestroy {
  @Input() formGroupControl: IDynamicViewControl = null;
  @Input() data: IKeyValuePairAny = null;
  @Output() medicationsUpdated: EventEmitter<IKeyValuePairAny> = new EventEmitter();

  errorMessage = null;
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public modalController: ModalController,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    if (!this.formGroupControl) {
      this.errorMessage = 'Key not specified.';
      return;
    }

    if (!this.data || !this.data.value) {
      this.data = { key: this.formGroupControl.key, value: [] };
    }
  }

  async openMedicationSearch() {
    const modal = await this.modalController.create({
      component: MedicationSearchComponent,
      cssClass: 'modal-100'
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      if (!this.data || !this.data.value) {
        this.data = { key: this.formGroupControl.key, value: [] };
      }
      if (!data.frequency) {
        data.frequency = 'daily';
      }

      // check if exists
      if (this.data.value.find((m) => m.medicationId === data.medicationId)) {
        this.presentToast(`${data.medicationName} has already been added.`);
      } else {
        this.data.value.push(data);
      }
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  removeMedication(medication) {
    const itemIndex = this.data.value.findIndex((m) => m.medicationId === medication.medicationId);
    if (itemIndex > -1) {
      this.data.value.splice(itemIndex, 1);
    }
  }

  ngOnDestroy() {
    this.medicationsUpdated.emit(this.data);
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
