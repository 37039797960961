import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalToastComponent } from '../components/global-toast/global.toast.component';
// import { MonitoringService } from '../services/monitoring-service';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(private toast: GlobalToastComponent) {}

  //, private monitoringService: MonitoringService

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the call fails, retry first before throwing an error
    return next.handle(request).pipe(
      // retry(1),
      catchError((error) => this.errorHandler(error))
    );
  }

  private errorHandler(response: any): Observable<HttpEvent<any>> {
    // this.monitoringService.logException(response);
    if (response.status === 0) {
      response.message = 'Your network is unavailable. Check your data or wifi connection.';
    } else {
      response.message = 'An error occurred while processing your request. Please try again.';
    }
    // this.toast.presentToast(response?.error?.message ? response?.error?.message : response?.message);
    // this.toast.presentToast(response.message);
    throw response;
  }
}
