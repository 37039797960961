<div class="reset-password">
  <ion-card color="primary" class="reset-password-content ion-padding">
    <img src="assets/img/logo.png" alt="nodeMe Logo" />
    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <ion-item lines="none">
        <ion-input type="password" autocomplete="new-password" formControlName="newPassword" [required]="true"
          [minlength]="6" errorText="Password is required">
          <ion-label slot="label" color="primary" position="stacked">New Password</ion-label>
        </ion-input>
      </ion-item>
      <p>{{error}}</p>
      <ion-button expand="full" color="primary" type="submit" [disabled]="!resetForm.valid">Reset Password</ion-button>
    </form>
  </ion-card>
</div>