<div class="previous-surgeries-content">
  <ion-list>
    @for (item of previousSurgeries; track item) {
    <ion-item>
      <ion-label class="list-item">
        <h2>{{ item.description }}</h2>
        <h3>{{ item.surgeon }}</h3>
        <p>{{ item.date | date: 'yyyy/MM/dd' }}</p>
      </ion-label>
    </ion-item>
    }
  </ion-list>

  <ion-list>
    @for (item of data?.value; track item) {
    <ion-item>
      <ion-button slot="end" fill="clear" (click)="removeSurgery(item)">
        <ion-icon slot="icon-only" name="trash-outline" color="primary"> </ion-icon>
      </ion-button>
      <ion-label class="list-item">
        <h2>{{ item.description }}</h2>
        <h3>{{ item.surgeon }}</h3>
        <p>{{ item.date | date: 'yyyy/MM/dd' }}</p>
      </ion-label>
    </ion-item>
    }
    <ion-item lines="none">
      <ion-button class="addition-button" (click)="openSurgeryAdditionModal()" fill="clear" color="tertiary">ADD
        SURGERY</ion-button>
    </ion-item>
  </ion-list>
</div>