import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPasswordForgotIn } from '@app/core/contracts/dto';
import { AuthService } from '@app/core/services/auth-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './forgot-password.html',
  styleUrls: ['./forgot-password.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  requestForm: FormGroup;
  username: FormControl;
  submitted = false;
  loading = false;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.initFields();
    this.buildForm();
  }

  initFields() {
    this.username = new FormControl('', [Validators.required, Validators.minLength(4)]);
  }

  buildForm() {
    this.requestForm = new FormGroup({
      username: this.username
    });
  }

  submit() {
    const p: IPasswordForgotIn = {
      email: this.username.value
    };

    this.username.disable();
    this.loading = true;
    this.authService
      .postRequestPasswordReset(p)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: () => {
          this.username.enable();
          this.submitted = true;
          this.loading = false;
        },
        error: () => {
          this.username.enable();
          this.loading = false;
        }
      });
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
