import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IActivatePatientUserIn, IBarePatient, ILoginIn } from '@app/core/contracts/dto';
import { AuthService } from '@app/core/services/auth-service';
import { UserService } from '@app/core/services/user-service';
import { LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  error = '';
  authForm: FormGroup;

  activationCode: FormControl;
  password: FormControl;
  confirmPassword: FormControl;
  username: FormControl;
  tcs: FormControl;
  mobileNumber: FormControl;

  userPatient: IBarePatient = null;
  src = null; // 0 = entry from sms, 1 = entry from email
  activationSuccess = false;
  biometricsEnabled = false;

  constructor(
    private authService: AuthService,
    private loadingController: LoadingController,
    private route: ActivatedRoute,
    public platform: Platform,
    private toastController: ToastController,
    private userService: UserService,
    private nav: NavController
  ) {}

  async ngOnInit() {
    this.createFormControls();
    this.createForm();

    this.authService.logout();

    this.route.params.pipe(takeUntil(this.componentDestroyed$)).subscribe({
      next: async (params) => {
        const code = params['code'];
        if (code) {
          this.activationCode.setValue(code);
          await this.getPatientByActivationCode();
        }

        this.src = params['src'];
      }
    });
  }

  createFormControls() {
    this.activationCode = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.username = new FormControl('', [Validators.required, Validators.email]);
    this.password = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.confirmPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.tcs = new FormControl(false, [Validators.required]);
    this.mobileNumber = new FormControl('', [Validators.minLength(10)]);
  }

  createForm() {
    this.authForm = new FormGroup({
      activationCode: this.activationCode,
      username: this.username,
      mobileNumber: this.mobileNumber,
      password: this.password,
      tcs: this.tcs,
      confirmPassword: this.confirmPassword
    });
  }

  async activateAccount() {
    // activate account
    if (!this.activationCode.value || !this.userPatient || !this.authForm.valid || this.password.value !== this.confirmPassword.value) {
      return;
    }

    const loading = await this.loadingController.create({
      message: 'Please wait...'
    });

    await loading.present();

    const data: IActivatePatientUserIn = {
      activationCode: this.activationCode.value,
      patientId: this.userPatient.id,
      username: this.username.value,
      password: this.password.value,
      cellPhone: this.mobileNumber.value,
      activationSourceId: this.src
    };

    this.authService
      .activatePatientUser(data)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: async (res) => {
          this.authService.userName = data.username;
          localStorage.setItem('refresh_token', res.refreshToken);
          this.activationSuccess = true;
          await loading.dismiss();
        },
        error: async (error) => {
          await loading.dismiss();

          if (error?.error?.message?.toLowerCase().indexOf('user already exists') > -1) {
            await this.presentUserExistsToast(data.username);
            this.nav.navigateRoot(['/login']);
          } else {
            await this.presentToast('Error activating user account: ' + error?.error?.message);
          }
        }
      });
  }

  async login() {
    const login: ILoginIn = {
      username: this.username.value,
      password: this.password.value
    };

    const loading = await this.loadingController.create({
      message: 'Please wait...'
    });

    await loading.present();

    this.authService
      .login(login)
      .pipe(
        concatMap(() => this.authService.setUserContext()),
        concatMap(() => this.userService.setUserPatient(this.authService.userContext.patientId))
      )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: () => {
          loading.dismiss();
          this.nav.navigateRoot('/secure/account');
        },
        error: () => {
          loading.dismiss();
          this.error = 'Incorrect Username / Password';
          setTimeout(() => {
            this.error = '';
          }, 5000);
        }
      });
  }

  async getPatientByActivationCode() {
    if (!this.activationCode.valid) {
      return;
    }

    const loading = await this.loadingController.create({
      message: 'Please wait...'
    });

    await loading.present();

    this.authService
      .getPatientByActivationCode(this.activationCode.value)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: async (data) => {
          this.userPatient = data;
          this.username.setValue(this.userPatient.email);
          this.mobileNumber.setValue(this.userPatient.cellPhone);
          await loading.dismiss();
        },
        error: async (error) => {
          await loading.dismiss();
          this.presentToast(error?.error?.message);
          this.nav.navigateRoot(['/login']);
        }
      });
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 8000
    });
    toast.present();
  }

  async presentUserExistsToast(username: string) {
    const toast = await this.toastController.create({
      header: 'Activation Error',
      message: `Username ${username} already exists`,
      duration: 8000,
      cssClass: 'action-toast',
      buttons: [
        {
          side: 'end',
          icon: 'login',
          text: 'LOGIN',

          handler: () => {
            this.nav.navigateRoot(['/login']);
          }
        }
      ]
    });
    toast.present();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
