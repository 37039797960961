import { JwtHelperService } from '@auth0/angular-jwt';
import { GlobalToastComponent } from './components/global-toast/global.toast.component';
// import { GlobalVersionComponent } from './components/version-modal/version-modal';
// import { VersionModalComponent } from './components/version-modal/version.component';
import { AuthGuard } from './services/auth-guard';
import { AuthService } from './services/auth-service';
import { DataService } from './services/data-service';
import { ErrorHandlerService } from './services/errorhandler-service';
import { MonitoringService } from './services/monitoring.service';

export const featureServices = [
  ErrorHandlerService,
  JwtHelperService,
  DataService,
  AuthService,
  AuthGuard,
  GlobalToastComponent,
  // GlobalVersionComponent,
  // FingerprintAIO
  MonitoringService
];

export const featureDialogs: any[] = [];

export const featureComponents: any[] = [GlobalToastComponent];

export const featureDirectives: any[] = [];

export const featurePipes: any[] = [];
