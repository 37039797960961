import { AfterViewInit, Component, NgZone, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { environment } from '@env/environment';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  template: ` <ion-router-outlet></ion-router-outlet> `
})
export class AppComponent implements AfterViewInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  public constructor(
    public platform: Platform,
    private titleService: Title,
    private router: Router,
    private zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.platform.ready().then(() => {
      this.titleService.setTitle('NodeMe' + environment.version);
      this.initializeApp();
    });
  }

  initializeApp() {
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        const slug = data.url.split('.za').pop();
        if (slug) {
          setTimeout(() => {
            this.router.navigateByUrl(slug);
          }, 1000);
        }
      });
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
