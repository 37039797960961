import { Component, OnDestroy } from '@angular/core';
import { format } from 'date-fns';
import { Subject } from 'rxjs';

@Component({
  selector: 'layout-public',
  styleUrls: ['./public.scss'],
  templateUrl: './public.html'
})
export class PublicLayoutComponent implements OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  year: string = format(new Date(), 'yyyy');

  constructor() {}
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
