import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { material, sharedComponents, sharedDialogs, sharedPipes } from './shared.declarations';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true
    }),
    ...material,
    FormsModule,
    ReactiveFormsModule,
    NgxExtendedPdfViewerModule
  ],
  declarations: [...sharedPipes, ...sharedComponents, ...sharedDialogs],
  exports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, ...sharedPipes, ...sharedComponents, ...sharedDialogs, ...material]
})
export class SharedModule {}
