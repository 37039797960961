export enum DynamicFieldType {
  FormGroup = 1,
  CheckBox = 2,
  TextInput = 3,
  ToggleYesNo = 4,
  ComponentMedicationManager = 5,
  TextAreaInput = 6,
  ComponentMedicalProblemManager = 7,
  ComponentPreviousSurgeries = 8,
  DialogToggleInstruction = 9,
  Label = 10
}
