@if (!errorMessage) {
<ion-content class="dynamic-form-content" *ngIf="!errorMessage">
  @if (formBuilt) {
  <div>
    <form [formGroup]="dynamicForm">
      <ion-card class="ion-no-padding">
        <ion-card-header>
          <ion-card-title>{{ formGroupControl.generalDescription }}</ion-card-title>
        </ion-card-header>
        <ion-card-content class="ion-no-padding">
          <ion-grid class="ion-no-padding">
            @for (c of controls; track c) {
            <ion-row [hidden]="c.hidden" class="ion-no-padding">
              <ion-col>
                @switch (c.fieldType) {

                <!-- CheckBox -->
                @case (fieldTypes.CheckBox) {
                <ng-container>
                  <ion-item lines="none" [ngClass]="{
                                        'control-level-1': c.level === 1,
                                        'control-level-2': c.level === 2,
                                        'control-level-3': c.level === 3,
                                        'control-level-4': c.level === 4,
                                        'control-level-5': c.level === 5
                                      }">
                    <ion-checkbox formControlName="{{ c.key }}" color="tertiary" (ionChange)="setHiddenProperty()">
                      {{ c.generalDescription }}
                    </ion-checkbox>
                  </ion-item>
                </ng-container>
                }
                <!-- CheckBox -->

                <!-- TextInput -->
                @case (fieldTypes.TextInput) {
                <ng-container>
                  <ion-item lines="none" [ngClass]="{
                                                      'control-level-1': c.level === 1,
                                                      'control-level-2': c.level === 2,
                                                      'control-level-3': c.level === 3,
                                                      'control-level-4': c.level === 4,
                                                      'control-level-5': c.level === 5
                                                    }">
                    <ion-input formControlName="{{ c.key }}" [placeholder]="c.generalDescription"></ion-input>
                  </ion-item>
                </ng-container>
                }
                <!-- TextInput -->

                <!-- ToggleYesNo -->
                @case (fieldTypes.ToggleYesNo) {
                <ng-container>
                  <ion-item lines="none" [ngClass]="{
                                                      'control-level-1': c.level === 1,
                                                      'control-level-2': c.level === 2,
                                                      'control-level-3': c.level === 3,
                                                      'control-level-4': c.level === 4,
                                                      'control-level-5': c.level === 5
                                                    }">
                    <ion-label>{{ c.generalDescription }}</ion-label>
                    <ion-toggle justify="end" color="secondary" [enableOnOffLabels]="true" formControlName="{{ c.key }}"
                      (ionChange)="setHiddenProperty()">
                      <span *ngIf="dynamicForm.get(c.key).value === true">Yes</span>
                      <span *ngIf="dynamicForm.get(c.key).value !== true">No</span>
                    </ion-toggle>
                  </ion-item>
                </ng-container>
                }
                <!-- ToggleYesNo -->

                <!-- MedicationManager -->
                @case (fieldTypes.ComponentMedicationManager) {
                <ng-container>
                  <app-medication-manager [data]="getCustomControlData(c.key)" [formGroupControl]="c"
                    (medicationsUpdated)="customComponentUpdated($event)"></app-medication-manager>
                </ng-container>
                }
                <!-- MedicationManager -->

                <!-- TextArea -->
                @case (fieldTypes.TextAreaInput) {
                <ng-container>
                  <ion-item>
                    <ion-textarea formControlName="{{ c.key }}" rows="6" [label]="c.generalDescription"
                      labelPlacement="stacked" placeholder="Start typing here...">
                    </ion-textarea>
                  </ion-item>
                </ng-container>
                }
                <!-- TextArea -->

                <!-- MedicalProblemManager -->
                @case (fieldTypes.ComponentMedicalProblemManager) {
                <ng-container>
                  <app-medical-problem-manager [data]="getCustomControlData(c.key)" [formGroupControl]="c"
                    (medicalProblemsUpdated)="customComponentUpdated($event)"></app-medical-problem-manager>
                </ng-container>
                }
                <!-- MedicalProblemManager -->

                <!-- PreviousSurgeries -->
                @case (fieldTypes.ComponentPreviousSurgeries) {
                <ng-container>
                  <app-previous-surgeries [data]="getCustomControlData(c.key)" [formGroupControl]="c"
                    [appointments]="formData.appointments" (surgeriesUpdated)="customComponentUpdated($event)">
                  </app-previous-surgeries>
                </ng-container>
                }
                <!-- PreviousSurgeries -->

                <!-- Label -->
                @case (fieldTypes.Label) {
                <ng-container>
                  <div class="label-field-type-container">
                    <ion-label text-wrap class="label-field">{{ c.generalDescription }}</ion-label>
                  </div>
                </ng-container>
                }
                <!-- Label -->
                }
              </ion-col>
            </ion-row>
            }
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </form>
  </div>
  }
</ion-content>
} @else {
<ion-content class="dynamic-form-content">
  <h4 class="control-error">{{ errorMessage }}</h4>
</ion-content>
}