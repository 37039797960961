<div class="request">
  <ion-card class="request-content ion-padding">
    <img src="assets/img/logo.png" alt="nodeMe Logo" />
    <ion-note>
      <p>Please enter your nodeMe username.</p>
      <p>You will receive an email with instructions to reset your password.</p>
    </ion-note>
    @if (!submitted) {
    <form [formGroup]="requestForm" (ngSubmit)="submit()">
      <ion-item lines="none">
        <ion-input type="email" autocomplete="username" formControlName="username" [required]="true"
          errorText="Username is required">
          <ion-label slot="label" color="primary">Username<ion-text color="danger">&nbsp;*</ion-text></ion-label>
        </ion-input>
      </ion-item>
      <div align="end">
        <ion-button fill="clear" color="primary" [routerLink]="['']">Back</ion-button>
        <ion-button fill="solid" color="primary" type="submit" [disabled]="!username.valid || loading">Send verification
          email </ion-button>
      </div>
    </form>
    } @else {
    <div>
      <ion-item>
        <ion-label class="white-space-initial"> Please follow reset instructions in email. </ion-label>
      </ion-item>
      <ion-button fill="solid" color="primary" [routerLink]="['']">Back</ion-button>
    </div>
    }
  </ion-card>
</div>