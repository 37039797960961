import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IPatientFull, IPatientFullOut } from '../contracts/dto';
import { DataService } from './data-service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private dataService: DataService) {}
  userPatient: IPatientFull;

  getDashboardSummary() {
    return this.dataService.getDashboardSummary();
  }

  setUserPatient(patientId: string) {
    return this.dataService.getPatientById(patientId).pipe(
      map((res) => {
        this.userPatient = res;
      })
    );
  }

  updatePatientUser(patientId: string, patient: IPatientFullOut) {
    return this.dataService.updatePatientUser(patientId, patient);
  }
}
