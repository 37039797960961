import { Component } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'toast-component',
  template: ''
})
export class GlobalToastComponent {
  constructor(public toastController: ToastController) {}

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      color: 'primary',
      duration: 3000,
      cssClass: 'global-toast'
    });

    await toast.present();
  }
}
