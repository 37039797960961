<ion-header>
  <ion-toolbar>
    <ion-title>Add medical problem</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="add-medical-problem-content">
  <form [formGroup]="medicalProblemForm">
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-input type="text" formControlName="name" placeholder="Name problem...">
            <ion-label slot="label" class="fit-label" color="primary">Medical problem</ion-label>
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item>
          <ion-textarea formControlName="description" rows="6" placeholder="Add details...">
            <ion-label slot="label" color="primary">Details</ion-label>
          </ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row>
  </form>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div class="dialog-button-container">
      <ion-button (click)="cancel()" color="primary">CANCEL</ion-button>
      <ion-button (click)="addItem()" [disabled]="!medicalProblemForm.valid" color="primary">ADD</ion-button>
    </div>
  </ion-toolbar>
</ion-footer>