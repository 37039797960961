import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services/auth-service';
import { DataService } from '@app/core/services/data-service';
import { LoadingController, NavController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './reset-password.html',
  styleUrls: ['./reset-password.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  resetForm: FormGroup;
  oldPassword: FormControl;
  newPassword: FormControl;
  error = '';

  constructor(
    private nav: NavController,
    private dataService: DataService,
    private loadingController: LoadingController,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.oldPassword = new FormControl(this.authService.resetPasswordCode, [Validators.required, Validators.minLength(6)]);
    this.newPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
    if (this.authService.resetPasswordCode && this.authService.resetPasswordCode.length > 0) {
      this.oldPassword.disable();
    }
  }

  createForm() {
    this.resetForm = new FormGroup({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    });
  }

  async onSubmit() {
    if (this.resetForm.valid) {
      const loading = await this.loadingController.create({
        message: 'Please wait...'
      });

      await loading.present();

      this.oldPassword.enable();
      this.dataService.resetPassword(this.resetForm.value).subscribe({
        next: async () => {
          this.authService.resetPasswordCode = '';
          this.nav.navigateRoot(['/secure/dashboard']);
        },
        error: () => {
          loading.dismiss();
          this.error = 'Incorrect Password';
          setTimeout(() => {
            this.error = '';
          }, 5000);
        }
      });
    } else {
      this.error = 'Password is required';
      setTimeout(() => {
        this.error = '';
      }, 5000);
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
