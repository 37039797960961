import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IUnspecifiedMedicalProblem } from '@app/core/contracts/dto';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-medical-problem',
  templateUrl: './add-medical-problem.component.html',
  styleUrls: ['./add-medical-problem.component.scss']
})
export class AddMedicalProblemComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  medicalProblemForm: FormGroup;
  name: FormControl;
  description: FormControl;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.name = new FormControl('', [Validators.required, Validators.minLength(3)]);
    this.description = new FormControl('', [Validators.required, Validators.minLength(6)]);
  }

  createForm() {
    this.medicalProblemForm = new FormGroup({
      name: this.name,
      description: this.description
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  addItem() {
    if (!this.medicalProblemForm.valid) {
      return;
    }

    const item: IUnspecifiedMedicalProblem = {
      name: this.name.value,
      description: this.description.value
    };

    this.modalController.dismiss(item);
  }

  cancel() {
    this.modalController.dismiss();
  }
}
