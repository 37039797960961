<ion-toolbar color="primary">
  <ion-title>{{ header | uppercase }}</ion-title>
</ion-toolbar>
<ion-grid class="ion-no-margin">
  <ion-row>
    <ion-col>
      <ion-item lines="none">
        <ion-searchbar type="text" #searchbar [debounce]="400" [(ngModel)]="searchValue" (ionInput)="searchInput()"
          (ionChange)="searchInput()" showCancelButton="always" (ionCancel)="cancelSearch()"> </ion-searchbar>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="options-row">
    <ion-col class="ion-no-padding">
      <ion-list class="ion-no-padding">
        @for (item of filteredOptions; track $index) {
        <ion-item (click)="setValue(item)">
          <ion-label>{{ item.medicalAid?.name }} - {{ item.medicalAidPlan?.name }}</ion-label>
        </ion-item>
        }
      </ion-list>
    </ion-col>
  </ion-row>
</ion-grid>