<div class="medical-problem-manager-content">
  <ion-list>
    @for (item of data?.value; track item) {
    <ion-item>
      <ion-button slot="end" fill="clear" (click)="removeMedicalProblem(item)">
        <ion-icon slot="icon-only" name="trash-outline" color="primary"> </ion-icon>
      </ion-button>
      <ion-label>
        <h2>{{ item.name }}</h2>
        <p>{{ item.description }}</p>
      </ion-label>
    </ion-item>
    }
    <ion-item lines="none">
      <ion-button class="addition-button" (click)="openMedicalProblemAdditionModal()" fill="clear"
        color="tertiary">ADD</ion-button>
    </ion-item>
  </ion-list>
</div>