import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { IActivatePatientUserIn, ILoginIn, IPasswordForgotIn } from '../contracts/dto';
import { DataService } from './data-service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  error: string;
  // userContext: IBareUserContext;
  resetPasswordCode: string;
  userName: string;
  userContext: any;

  constructor(
    private dataService: DataService,
    private jwtHelper: JwtHelperService
  ) {
    this.userContext = JSON.parse(localStorage.getItem('user_context') as string);
  }

  refresh() {
    this.setUserContext().subscribe();
  }

  public authenticated(): Observable<boolean> {
    let token = localStorage.getItem('id_token') as string;
    let ex = !this.jwtHelper.isTokenExpired(token);
    if (ex && this.userContext) {
      return of(true);
    } else {
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken && this.userContext) {
        return this.dataService.getPingResult().pipe(
          map(() => {
            token = localStorage.getItem('id_token') as string;
            ex = !this.jwtHelper.isTokenExpired(token);
            return ex;
          })
        );
      } else {
        return of(false);
      }
    }
  }

  login(credentials: ILoginIn) {
    return this.dataService.loginUser(credentials).pipe(
      map((res) => {
        // store the new tokens
        localStorage.setItem('refresh_token', res.refreshToken);
        localStorage.setItem('id_token', res.token);
        // const token = this.jwtHelper.decodeToken(res.token);
        // this.monitoringService.setUserId(token.userId);
        return res;
      })
    );
  }

  refreshToken(): Observable<string> {
    // append refresh token if you have one
    const refreshToken = localStorage.getItem('refresh_token');

    return this.dataService.refeshToken(refreshToken as string).pipe(
      share(),
      map((res) => {
        // store the new tokens
        localStorage.setItem('refresh_token', res.refreshToken);
        localStorage.setItem('id_token', res.token);
        const token = this.jwtHelper.decodeToken(res.token);
        return res.token;
      })
    );
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('user_context');
    this.userContext = null;
  }

  setUserContext() {
    return this.dataService.getUserContext().pipe(
      map((res) => {
        this.userContext = res;
        localStorage.setItem('user_context', JSON.stringify(this.userContext));
      })
    );
  }

  getToken(): Observable<string> {
    const token = localStorage.getItem('id_token') as string;
    const isTokenExpired = this.jwtHelper.isTokenExpired(token);
    if (!isTokenExpired) {
      return of(token);
    }
    return this.refreshToken();
  }

  postRequestPasswordReset(reset: IPasswordForgotIn) {
    return this.dataService.postRequestPasswordReset(reset);
  }

  getPatientByActivationCode(code: string) {
    return this.dataService.getPatientByActivationCode(code);
  }

  activatePatientUser(data: IActivatePatientUserIn) {
    return this.dataService.postPatientUserActivation(data);
  }
  sendOTP(patientId: string, mobileNumber: string = null, verify: boolean = false) {
    return this.dataService.sendOTP(patientId, mobileNumber, verify);
  }
}
