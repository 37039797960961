import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActivateComponent } from './components/activate/activate.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password';
import { CoreModule } from './core/core.module';
import { AppLayoutComponent } from './layout/app';
import { PublicLayoutComponent } from './layout/public';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, AppLayoutComponent, PublicLayoutComponent, LoginComponent, ActivateComponent, ForgotPasswordComponent, ResetPasswordComponent],
  imports: [BrowserModule, AppRoutingModule, CoreModule.forRoot(), SharedModule, FormsModule, ReactiveFormsModule, BrowserAnimationsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {}
