<div class="login">
  <div>
    <ion-card color="primary" class="login-content ion-padding">
      <img src="assets/img/logo.png" alt="nodeMe Logo" />
      <form [formGroup]="authForm" (ngSubmit)="onSubmit()">
        <ion-item lines="none">
          <ion-input type="text" autocomplete="username" formControlName="username" [required]="true" label="Username"
            labelPlacement="stacked" errorText="Username is required">
          </ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-input type="password" autocomplete="current-password" formControlName="password" [required]="true"
            label="Password" labelPlacement="stacked" errorText="Password is required">
          </ion-input>
        </ion-item>
        @if (error) {
        <ion-item lines="none">
          <p>
            <ion-note color="danger"><b>{{ error }}</b></ion-note>
          </p>
        </ion-item>
        }
        <ion-button expand="full" color="primary" type="submit" [disabled]="!username.valid || !password.valid">Sign
          in</ion-button>
        <ion-button expand="full" fill="clear" color="secondary" [routerLink]="['/forgotpassword']">Forgot
          Password</ion-button>
      </form>
    </ion-card>
  </div>
</div>