import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterState } from '@angular/router';
import { environment } from '@env/environment';
import { NavController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Page } from '../core/contracts/dto';
import { PendingRequestsInterceptor } from '../core/interceptors/pending-requests-interceptor';
import { AuthService } from '../core/services/auth-service';

@Component({
  selector: 'layout-app',
  styleUrls: ['./app.scss'],
  templateUrl: './app.html'
})
export class AppLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  titleData: string = '';
  backLink: string;
  appVersion: string = environment.version;

  public appPages: Page[] = [
    {
      title: 'Dashboard',
      url: '/secure/dashboard',
      index: 0,
      icon: 'home-outline'
    },
    {
      title: 'Personal Information',
      url: '/secure/account',
      index: 1,
      icon: 'person-outline'
    },
    {
      title: 'My Health',
      url: '/secure/health',
      index: 2,
      icon: 'medkit-outline'
    },
    {
      title: 'Appointments',
      url: '/secure/procedures',
      index: 4,
      icon: 'calendar-outline'
    },
    {
      title: 'Estimates',
      url: '/secure/estimates',
      index: 5,
      icon: 'calculator-outline'
    },
    {
      title: 'Accounts',
      url: '/secure/invoices',
      index: 6,
      icon: 'wallet-outline'
    },
    {
      title: 'Information',
      url: '/secure/information',
      index: 7,
      icon: 'information-circle-outline'
    }
  ];
  isLoading$: Observable<boolean>;
  public selectedIndex = 0;
  constructor(
    public router: Router,
    private nav: NavController,
    public authService: AuthService,
    private pendingRequestsInterceptor: PendingRequestsInterceptor
  ) {}

  ngOnInit() {
    this.titleData = this.getTitle(this.router.routerState, this.router.routerState.root);
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.titleData = this.getTitle(this.router.routerState, this.router.routerState.root);
        }
        if (event instanceof NavigationStart) {
          this.backLink = this.router.url.toString();
        }
      }
    });
    if (!this.authService.userContext) {
      this.authService.logout();
      this.router.navigate([''], {
        queryParams: { returnUrl: this.router.routerState.snapshot.url }
      });
    }
  }

  ngAfterViewInit() {
    this.isLoading$ = this.pendingRequestsInterceptor.PendingRequestsStatus$.pipe(delay(0));
  }

  getTitle(state: RouterState, parent: ActivatedRoute) {
    let title: string = '';
    if (parent && parent.snapshot && parent.snapshot.data && parent.snapshot.data['title']) {
      title = parent.snapshot.data['title'];
    }

    if (state && parent) {
      const firstChild = parent.firstChild;
      if (firstChild) {
        title = this.getTitle(state, firstChild);
      }
    }

    return title;
  }

  logout() {
    this.authService.logout();
    this.nav.navigateRoot('');
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
