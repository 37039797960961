<ion-header>
  <ion-toolbar>
    <ion-title>
      <ion-grid>
        <ion-row>
          <ion-col size="2"><ion-icon name="arrow-back" (click)="back()"></ion-icon></ion-col>
          <ion-col size="10">{{ resource?.name }}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-title>
  </ion-toolbar>
</ion-header>

@if (resource?.contentTypeId === contentType.Pdf) {
  <ion-content class="modal-content">
    <ngx-extended-pdf-viewer [src]="resource.url" [showOpenFileButton]="false" [showTextEditor]="false"
    [showDrawEditor]="false" [showStampEditor]="false" [showHighlightEditor]="false"></ngx-extended-pdf-viewer>
  </ion-content>
}