import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-time-estimate-modal',
  templateUrl: './time-estimate-modal.component.html',
  styleUrls: ['./time-estimate-modal.component.scss']
})
export class TimeEstimateModalComponent {
  constructor(private modalController: ModalController) {}

  close() {
    this.modalController.dismiss();
  }
}
