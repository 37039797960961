import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { IDynamicViewControl, IKeyValuePairAny, IPatientAppointment, ISurgery } from '@app/core/contracts/dto';
import { SpecialityCategory } from '@app/core/enums/speciality-category-enum';
import { WorksessionType } from '@app/core/enums/worksession-type.enum';
import { ModalController, ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { AddSurgeryComponent } from './add-surgery-modal/add-surgery.component';

@Component({
  selector: 'app-previous-surgeries',
  templateUrl: './previous-surgeries.component.html',
  styleUrls: ['./previous-surgeries.component.scss']
})
export class PreviousSurgeriesComponent implements OnInit, OnDestroy {
  @Input() formGroupControl: IDynamicViewControl = null;
  @Input() data: IKeyValuePairAny = null;
  @Input() appointments: IPatientAppointment[] = [];
  @Output() surgeriesUpdated: EventEmitter<IKeyValuePairAny> = new EventEmitter();

  previousSurgeries: ISurgery[] = []; // system
  errorMessage = null;
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public modalController: ModalController,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    if (!this.formGroupControl) {
      this.errorMessage = 'Key not specified.';
      return;
    }

    if (!this.data || !this.data.value) {
      this.data = { key: this.formGroupControl.key, value: [] };
    }

    this.appointments.forEach((app) => {
      if (app.worksessionTypeId === WorksessionType.Procedure) {
        const surgeon = app.practitioners.find((p) => p.speciality?.category === SpecialityCategory.Surgery);

        this.previousSurgeries.push({
          date: app.fromDate,
          description: app.mainProcedure?.description,
          surgeon: `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}`
        });
      }
    });
  }

  async openSurgeryAdditionModal() {
    const modal = await this.modalController.create({
      component: AddSurgeryComponent,
      cssClass: 'modal-100'
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();

    if (data) {
      if (!this.data || !this.data.value) {
        this.data = { key: this.formGroupControl.key, value: [] };
      }

      // check if exists
      if (this.data.value.find((m) => m.description.toLowerCase().replace(' ', '') === data.description.toLowerCase().replace(' ', '') && m.date === data.date)) {
        this.presentToast(`${data.description} has already been added.`);
      } else {
        this.data.value.push(data);
      }
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  removeSurgery(item) {
    const itemIndex = this.data.value.findIndex((m) => m.description.toLowerCase().replace(' ', '') === item.description.toLowerCase().replace(' ', '') && m.date === item.date);
    if (itemIndex > -1) {
      this.data.value.splice(itemIndex, 1);
    }
  }

  ngOnDestroy() {
    this.surgeriesUpdated.emit(this.data);
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
