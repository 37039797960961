<div class="toggle-instruction-content">
  <ion-grid>
    <ion-row>
      <ion-col>
        <h3>
          {{ message }}
        </h3>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="5">
        <ion-toggle class="center-item" color="secondary" labelPlacement="before" [checked]="true" [disabled]="true"
          [enableOnOffLabels]="true">
          Yes
        </ion-toggle>
      </ion-col>
      <ion-col size="2" class="center-item">OR</ion-col>
      <ion-col size="5">
        <ion-toggle class="center-item" color="secondary" labelPlacement="before" [checked]="false" [disabled]="true"
          [enableOnOffLabels]="true">
          No
        </ion-toggle>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-button (click)="close()" color="primary"> I UNDERSTAND </ion-button>
</div>