import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IBareMedicalAid, IBareMedicalAidPlan } from '@app/core/contracts/dto';
import { PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { MedicalAidAutocompletePopoverComponent } from './medical-aid-autocomplete-popover/medical-aid-autocomplete-popover.component';

@Component({
  selector: 'app-medical-aid-autocomplete',
  templateUrl: './medical-aid-autocomplete.component.html',
  styleUrls: ['./medical-aid-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MedicalAidAutocompleteComponent,
      multi: true
    }
  ]
})

// Expect key value pair input <string, string>
export class MedicalAidAutocompleteComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() label = 'Search';
  @Input() header = 'Find Entity';
  @Input() required = false;
  @Input() labelPosition = 'fixed';
  @Input() displayWith = displayFn;
  @Input() data: MedicalAidPlanOption[] = []; // Pass in autocomplete data
  @Output() searchValueChanged: EventEmitter<string> = new EventEmitter();
  @Output() valueChange: EventEmitter<MedicalAidPlanOption> = new EventEmitter();

  displayValue = '';

  options: MedicalAidPlanOption[] = [];
  options$: Subject<MedicalAidPlanOption[]> = new Subject();
  filteredOptions: MedicalAidPlanOption[] = [];

  searchActive = false;
  disabled = false;
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit() {
    this.setOptions();
  }

  ngOnChanges() {
    this.setOptions();
  }

  setOptions() {
    if (this.data) {
      const tempOptions = [];
      this.options = [];
      this.data.forEach((item) => {
        const option = item;
        tempOptions.push(option);
      });

      this.options = tempOptions;
      this.options$.next(tempOptions);
    }
  }

  openSearch() {
    this.presentPopover();
  }

  async presentPopover() {
    const popover = await this.popoverCtrl.create({
      component: MedicalAidAutocompletePopoverComponent,
      componentProps: {
        label: this.label,
        options: this.options$,
        header: this.header,
        searchValueChangedEvent: this.searchValueChanged
      },
      cssClass: 'popover-autocomplete'
    });

    popover.onDidDismiss().then((res) => {
      if (res.data) {
        this.setValue(res.data);
      }
    });

    await popover.present();
    if (this.options.length > 0) {
      this.options$.next(this.options);
    }
    return popover;
  }

  setValue(item: MedicalAidPlanOption) {
    this.writeValue(item);
    this.displayValue = displayFn(item);
    this.onTouch();
  }

  // Allow Angular to set the value on the component
  writeValue(value: MedicalAidPlanOption): void {
    if (!value) {
      this.displayValue = '';
    } else {
      this.displayValue = this.displayWith(value);
    }
    this.onChange(value);
    this.searchActive = false;
    this.valueChange.emit(value);
  }

  // Save a reference to the change function passed to us by
  // the Angular form control
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  // Save a reference to the touched function passed to us by
  // the Angular form control
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(fn: Function): void {
    this.onTouch = fn;
  }

  // Allow the Angular form control to disable this input
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  private onChange: Function = (option: MedicalAidPlanOption) => {
    if (option) {
      this.displayValue = displayFn(option);
    }
    // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  private onTouch: Function = () => {};
}

// Display second property
export const displayFn = (item: MedicalAidPlanOption | string) => {
  if (typeof item === 'string') {
    return item;
  } else if (typeof item.medicalAid === 'string') {
    return item.medicalAid;
  } else {
    return item.medicalAid ? item.medicalAid.name : 'undefined';
  }
};

export interface KeyValuePair {
  key: string | number;
  value: string | any;
}

export interface FilterOption {
  key: string | number;
  value: string;
}

export interface MedicalAidPlanOption {
  medicalAid: IBareMedicalAid;
  medicalAidPlan: IBareMedicalAidPlan;
}

export interface MedicalAidPlanFilterOption {
  medicalAid: IBareMedicalAid;
  medicalAidPlan: IBareMedicalAidPlan;
}
