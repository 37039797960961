import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MedicalAidAutocompletePopoverComponent } from './components/autocomplete/medical-aid-autocomplete-popover/medical-aid-autocomplete-popover.component';
import { MedicalAidAutocompleteComponent } from './components/autocomplete/medical-aid-autocomplete.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { GoogleMapsAutocompleteComponent } from './components/google-maps/google-maps-autocomplete.component';
import { InvoicesCompleteComponent } from './components/invoice-complete/invoice-complete';
import { AddMedicalProblemComponent } from './components/medical-problem-manager/add-medical-problem-modal/add-medical-problem.component';
import { MedicalProblemManagerComponent } from './components/medical-problem-manager/medical-problem-manager.component';
import { MedicationManagerComponent } from './components/medication-manager/medication-manager.component';
import { MedicationSearchComponent } from './components/medication-manager/medication-search-modal/medication-search.component';
import { AddSurgeryComponent } from './components/previous-surgeries/add-surgery-modal/add-surgery.component';
import { PreviousSurgeriesComponent } from './components/previous-surgeries/previous-surgeries.component';
import { ResourceViewerComponent } from './components/resource-viewer/resource-viewer.component';
import { TimeEstimateModalComponent } from './components/time-estimate-modal/time-estimate-modal.component';
import { ToggleInstructionComponent } from './components/toggle-instruction-modal/toggle-instruction.component';

export const material = [MatSlideToggleModule];

export const sharedDialogs: any[] = [MedicationSearchComponent, AddMedicalProblemComponent, AddSurgeryComponent, ResourceViewerComponent, ToggleInstructionComponent, TimeEstimateModalComponent];

export const sharedPipes: any[] = [];

export const sharedComponents: any[] = [
  GoogleMapsAutocompleteComponent,
  MedicalAidAutocompleteComponent,
  MedicalAidAutocompletePopoverComponent,
  InvoicesCompleteComponent,
  DynamicFormComponent,
  MedicationManagerComponent,
  MedicalProblemManagerComponent,
  PreviousSurgeriesComponent,
  ResourceViewerComponent
];
