import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MedicalAidPlanOption } from '../medical-aid-autocomplete.component';

@Component({
  selector: 'app-medical-aid-autocomplete-popover',
  templateUrl: './medical-aid-autocomplete-popover.component.html',
  styleUrls: ['./medical-aid-autocomplete-popover.component.scss']
})

// Expect key value pair input <string, string>
export class MedicalAidAutocompletePopoverComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() label = 'Search';
  @Input() header = 'Find Entity';
  @Input() options: Subject<MedicalAidPlanOption[]> = new Subject();
  @Input() searchValueChangedEvent: EventEmitter<string>;

  @ViewChild('searchbar', { static: false }) searchbar: IonSearchbar;
  componentDestroyed$: Subject<boolean> = new Subject();
  searchValue = '';

  selectableOptions: MedicalAidPlanOption[] = [];
  filteredOptions: MedicalAidPlanOption[] = [];

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit() {
    this.options.pipe(takeUntil(this.componentDestroyed$)).subscribe((data) => {
      if (data) {
        this.selectableOptions = data;
        this.applyFilter();
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.searchbar.setFocus(), 500);
  }

  cancelSearch() {
    this.popoverCtrl.dismiss();
  }

  searchInput() {
    this.searchValueChangedEvent.emit(this.searchValue);
    this.applyFilter();
  }

  applyFilter() {
    this.filteredOptions = this.selectableOptions.filter((item: MedicalAidPlanOption) => !this.searchValue || item.medicalAid?.name?.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1);
  }

  setValue(item: MedicalAidPlanOption) {
    this.popoverCtrl.dismiss(item);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
