/// <reference types="@types/google.maps" />
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { IGoogleMapsAddress } from '@app/core/contracts/dto';
import { Subject } from 'rxjs';

@Component({
  selector: 'google-maps-autocomplete',
  templateUrl: './google-maps-autocomplete.component.html',
  styleUrls: ['./google-maps-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapsAutocompleteComponent implements OnInit, AfterViewInit {
  @Input() label = 'Address';
  @Output() setAddress: EventEmitter<IGoogleMapsAddress> = new EventEmitter();
  @ViewChild('postalCode', { read: ElementRef }) postalCode;

  googleAutocomplete: google.maps.places.AutocompleteService;
  placesService: google.maps.places.PlacesService;
  geocoder: google.maps.Geocoder;
  componentDestroyed$: Subject<boolean> = new Subject();
  autocomplete: { input: string } = { input: '' };
  autocompleteItems: any[] = [];

  constructor(
    public zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.googleAutocomplete = new google.maps.places.AutocompleteService();
    this.geocoder = new google.maps.Geocoder();
  }

  ngAfterViewInit() {
    this.placesService = new google.maps.places.PlacesService(this.postalCode.nativeElement);
  }

  updateSearchResults() {
    this.autocompleteItems = [];

    if (this.autocomplete.input === '') {
      return;
    }

    this.googleSearchByAddress();
  }

  googleSearchByAddress() {
    this.googleAutocomplete.getPlacePredictions(
      {
        input: this.autocomplete.input,
        componentRestrictions: { country: 'ZA' },
        types: [] // 'establishment' / 'address' / 'geocode'
      },
      (predictions, status) => {
        this.zone.run(() => {
          if (!predictions) {
            this.cdr.markForCheck();
            return;
          }

          predictions.forEach((prediction) => {
            if (prediction.types.indexOf('establishment') === -1 && prediction.types.indexOf('point_of_interest') === -1) {
              this.autocompleteItems.push(prediction);
            }
          });
          this.cdr.markForCheck();
        });
      }
    );
  }

  async getAddressByPlaceId(placeId) {
    return new Promise((resolve, reject) => {
      const address: IGoogleMapsAddress = {
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        city: '',
        province: '',
        postalCode: '',
        country: ''
      };

      if (!placeId) {
        reject('placeId not provided');
      }

      try {
        this.placesService.getDetails(
          {
            placeId,
            fields: ['address_components', 'geometry']
          },
          (details) => {
            details?.address_components?.forEach((entry) => {
              if (entry.types?.[0] === 'street_number') {
                address.addressLine1 = entry.long_name;
              } else if (entry.types?.[0] === 'route') {
                address.addressLine1 = address.addressLine1 + ' ' + entry.long_name;
              } else if (entry.types?.[0]?.startsWith('sublocality')) {
                address.suburb = entry.long_name;
              } else if (entry.types?.[0] === 'locality') {
                address.city = entry.long_name;
              } else if (entry.types?.[0] === 'country') {
                address.country = entry.long_name;
              } else if (entry.types?.[0] === 'postal_code') {
                address.postalCode = entry.long_name;
              }
            });

            // postal code not set, get postal code using reverse geocoding
            if (!address.postalCode) {
              const latlng = {
                lat: details.geometry.location.lat(),
                lng: details.geometry.location.lng()
              };

              this.geocoder.geocode({ location: latlng }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                if (status === 'OK') {
                  if (results[0]) {
                    address.postalCode = results[0].address_components.find((a) => a.types[0] === 'postal_code')?.long_name;
                  }
                  return resolve(address);
                } else {
                  return resolve(address);
                }
              });
            } else {
              return resolve(address);
            }
          }
        );
      } catch (e) {
        reject(e);
      }
    });
  }
  async selectSearchResult(item) {
    const placeid = item.place_id;

    await this.getAddressByPlaceId(placeid).then((res) => {
      this.autocompleteItems = [];
      this.autocomplete = { input: '' };
      this.setAddress.emit(res);
    });
    this.cdr.markForCheck();
  }
}
