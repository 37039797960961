<ion-header>
  <ion-toolbar>
    <ion-title>Medications</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="medication-search-content">
  <ion-searchbar #searchbar [debounce]="400" (ionChange)="searchMedications($event)"
    (ionInput)="searchMedications($event)"></ion-searchbar>
  <ion-list>
    @for (m of medications; track m.medicationId) {
    <ion-item (click)="itemClicked(m)">
      <ion-label>{{ m.medicationName }} {{ m.dose }}{{ m.unit }}</ion-label>
    </ion-item>
    }
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()" color="primary">CANCEL</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>