<ion-header>
  <ion-toolbar>
    <ion-title>Add surgery</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="add-surgery-content">
  <form [formGroup]="surgeryForm">
    <ion-row>
      <ion-col>
        <ion-item class="inline-date-picker-item">
          <ion-label color="primary" position="fixed">Date<span class="required-field-indicator">*</span> </ion-label>
          <ion-datetime-button datetime="dateOfBirth"></ion-datetime-button>
          <ion-modal [keepContentsMounted]="true" [backdropDismiss]="false">
            <ng-template>
              <ion-datetime id="dateOfBirth" formControlName="date" presentation="date" [preferWheel]="true"
                [showDefaultButtons]="true"> </ion-datetime>
            </ng-template>
          </ion-modal>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item>
          <ion-input type="text" formControlName="description" placeholder="Type of surgery...">
            <ion-label slot="label" class="fit-label" color="primary">Surgery</ion-label>
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item>
          <ion-input type="text" formControlName="surgeon" placeholder="Surgeon's name...">
            <ion-label slot="label" class="fit-label" color="primary">Surgeon</ion-label>
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </form>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div class="dialog-button-container">
      <ion-button (click)="cancel()" color="primary">CANCEL</ion-button>
      <ion-button (click)="addSurgery()" [disabled]="!surgeryForm.valid" color="primary">ADD</ion-button>
    </div>
  </ion-toolbar>
</ion-footer>