import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { InvoiceType } from '../enums/invoice-type.enum';
import { DataService } from './data-service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(private dataService: DataService) {}

  getCountries() {
    return this.dataService.getCountries();
  }

  searchMedicalAidGroupings(q: string) {
    return this.dataService.searchMedicalAidGroupings(q);
  }

  getMedicalAidPlansByScheme(id: string) {
    return this.dataService.getMedicalAidPlansByScheme(id);
  }

  getPatientEstimates(patientId: string) {
    return this.dataService.getPatientAccounts(patientId, InvoiceType.Estimate);
  }

  getPatientInvoices(patientId: string) {
    return this.dataService.getPatientAccounts(patientId, InvoiceType.Invoice);
  }

  getPatientAccounts(patientId: string) {
    return this.dataService.getPatientAccounts(patientId, InvoiceType.All);
  }

  getPatientAppointments(patientId: string) {
    return this.dataService.getPatientAppointments(patientId);
  }

  acceptEstimate(estimateId: string) {
    return this.dataService.acceptEstimate(estimateId);
  }

  downloadInvoice(id: string, invoiceType: InvoiceType) {
    return this.dataService.downloadInvoice(id, invoiceType);
  }

  getDashboardData(patientId: string) {
    return forkJoin([this.getPatientAppointments(patientId), this.getPatientAccounts(patientId), this.getPatientInformationResources(patientId)]);
  }

  getPatientInformationResources(patientId: string) {
    return this.dataService.getPatientInformationResources(patientId).pipe(map(res => {
      return res.sort((a, b) => a.name.localeCompare(b.name));
    }));
  }
}
