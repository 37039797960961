import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });

    if (environment.appInsights.enableApplicationInsights) {
      this.appInsights.loadAppInsights();
      this.loadCustomTelemetryProperties();
    }
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }
  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      item['properties'] = item['properties'] || {};
      item['properties']['ApplicationPlatform'] = environment.platform;
      item['properties']['ApplicationName'] = 'NodeMe';
    });
  }
}
